Ember.TEMPLATES["javascripts/upgrade-header"] = Ember.HTMLBars.template({"id":null,"block":"[[[1,[28,[35,0],null,[[\"versionCheck\"],[[33,1]]]]],[1,\"\\n\"]],[],false,[\"upgrade-notice\",\"versionCheck\"]]","moduleName":"javascripts/upgrade-header","isStrictMode":false});
define("discourse/plugins/docker_manager/discourse/components/upgrade-notice", ["exports", "discourse-common/lib/get-url"], function (_exports, _getUrl) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    tagName: "tr",
    href: function () {
      return (0, _getUrl.default)("/admin/upgrade");
    }.property()
  });

  _exports.default = _default;
});
Ember.TEMPLATES["javascripts/components/upgrade-notice"] = Ember.HTMLBars.template({"id":null,"block":"[[[41,[33,1,[\"admin\"]],[[[1,\"  \"],[10,\"th\"],[14,\"colspan\",\"5\"],[12],[1,\"\\n\"],[41,[33,2,[\"upToDate\"]],[[[1,\"      \"],[10,3],[15,6,[29,[[36,3]]]],[14,\"data-auto-route\",\"true\"],[12],[1,[28,[35,4],[\"docker.link_to_upgrade\"],null]],[13],[1,\"\\n\"]],[]],[[[1,\"      \"],[1,[28,[35,4],[\"docker.upgrade\"],null]],[1,\" \"],[10,3],[15,6,[29,[[36,3]]]],[14,\"data-auto-route\",\"true\"],[12],[1,[28,[35,4],[\"docker.perform_upgrade\"],null]],[13],[1,\"\\n\"]],[]]],[1,\"  \"],[13],[1,\"\\n\"]],[]],null]],[],false,[\"if\",\"currentUser\",\"versionCheck\",\"href\",\"i18n\"]]","moduleName":"javascripts/discourse/templates/components/upgrade-notice","isStrictMode":false});

